@media only screen and (max-width: 800px) {
  /* Remove toolbar on small screens */
  //.sfbf-toolbar {
  //  display: none;
  //}
}

@media only print {
  .sfbf-toolbar{
    display: none !important;
  }
}


.focusable:focus {
  outline: none !important;
}

.has-focus {
  background: yellow;
}

.sfbf-toolbar{
  position: fixed;
  z-index: 99999;
  top: 43vh;
  left: 0;

  svg{
    fill: #ffffff;
  }

  /* reset styles */
  button {
    border: 0;
    color: inherit;
    background-color: transparent;
    text-decoration: none;
    padding: 0;
    line-height: inherit;
    margin: 0;
    font: inherit;
    width: 100%;
    height: 100%;
    text-align: left;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
  }

  ul li {
    margin: 0;
    padding: 0;
    display: block;
  }

  ul li button {
    width: 45px!important;
    padding: 14px 10px;
    line-height: .8;
    display: grid;
    align-items: center;
    background: #000000;
    text-align: center;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    position: relative;
    color: #ffffff;
  }

  ul li button.active,
  ul li button:hover {
    background: #181818;
    color: #fff;
  }

  ul li button.active {
    background: #181818;
    border-bottom: 1px solid #525252;
  }

  ul li:first-child button {
    border-radius: 0 4px 0 0;
  }

  ul li:last-child button {
    border-radius: 0 0 4px;
  }

  .a11y-toolbar-list .a11y-toolbar-list-item .tooltip-dismissed:focus .offscreen,
  .offscreen,
  .a11y_stylesheet_path {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0px 0px, 0px 0px,0px 0px, 0px 0px);
    white-space: nowrap;
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
    font-size: 16px;
    transition: all 200ms;
  }

  /* a11y toolbar RTL */
  .a11y-toolbar.rtl.default,
  .a11y-toolbar.ltr.reversed {
    right: 0;
    left: auto;
  }

  .a11y-toolbar.rtl.default ul,
  .a11y-toolbar.ltr.reversed ul {
    border-radius: 4px 0 0 4px;
  }

  .a11y-toolbar.rtl.default ul li:first-child button,
  .a11y-toolbar.ltr.reversed ul li:first-child button {
    border-radius: 4px 0 0 0;
  }

  .a11y-toolbar.rtl.default ul li:last-child button,
  .a11y-toolbar.ltr.reversed ul li:last-child button {
    border-radius: 0 0 0 4px;
  }

  .desaturated {
    filter: grayscale(1) !important;
  }

  button:hover .offscreen,
  button:focus .offscreen {
    height: auto !important;
    width: auto !important;
    clip: unset !important;
    clip-path: unset !important;
    color: #333;
    background: #f0f0f0;
    padding: 8px 6px !important;
    box-shadow: 0 0 1px #000;
    border-radius: 5px;
    font-size: 16px;
    text-transform: none;
    font-family: Arial;
  }

  &.right button:hover .offscreen,
  &.right button:focus .offscreen {
    right: 100%;
    left: auto;
  }

  button:hover .offscreen, button:focus .offscreen {
    left: 100%;
  }

}

body{
  --tec-font-size-0: #{11*$px-base};
  --tec-font-size-1: #{12*$px-base};
  --tec-font-size-2: #{14*$px-base};
  --tec-font-size-3: #{16*$px-base};
  --tec-font-size-4: #{18*$px-base};
  --tec-font-size-5: #{20*$px-base};
  --tec-font-size-6: #{22*$px-base};
  --tec-font-size-7: #{24*$px-base};
  --tec-font-size-8: #{28*$px-base};
  --tec-font-size-9: #{32*$px-base};
  --tec-font-size-10: #{42*$px-base};
}

body #legend_box{
  font-size: 10*$px-base;
}