.bottomnav {
  padding: 15px;
  margin-bottom: 10px;
  @include media-breakpoint-up(md) {
    text-align: center;
  }
  a{
    display: block;
    line-height: 2rem;
    color: $tertiary;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
  .seperator{
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.footer-gold{
  background: $primary;
}

.footerlogos{
  ul {
    list-style: none; /* Remove default bullets */
  }

  ul{
    li::before {
      color: $primary;
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
    li{
      a{
        color: #000000;
      }
    }
  }
}