
.goldenbackground{
  background: rgb(175,156,114);
  background: linear-gradient(45deg, rgba(175,156,114,1) 0%, rgba(237,233,224,1) 90%);
  overflow-x: hidden;
  @include media-breakpoint-down(xs) {
    width: 110%;
  }
  .row{
    overflow-x: hidden;
  }
}

.hintergrundbild{
  *{
    color: #ffffff !important;
  }
  .vc_btn3{
    color: $tertiary !important;
  }
}

.svg200 img{
  width: 200px;
}

.newsletterbox{
  ul{
    list-style: none;

    li{
      display: inline-flex;
      align-items: end;
    }
    li:before{
        content:'';
        display:inline-block;
        height:36px;
        width: 45px;
        background-image:url("images/icons/haken.svg");
        background-size:45px;
        background-repeat:no-repeat;
        padding-left: 2em;
        margin-left: -39px;
    }
  }
}

//ACCODION STYLING
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel.vc_active .vc_tta-panel-body{
 background-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-color-grey,
.wpb-js-composer .vc_tta.vc_general.vc_tta-color-black,
.wpb-js-composer .vc_tta.vc_general.vc_tta-color-white {
  .vc_tta-panel-heading{
    border-radius: 0px !important;
    .vc_tta-title-text{
      font-size: 16*$px-base;
      font-weight: bold;
    }
  }
}

.wpb-js-composer .vc_tta.vc_general.vc_tta-color-grey {
  .vc_tta-panel-heading{
    *{
      color: $secondary !important;
      border-color: $primary !important;
    }
    .vc_tta-controls-icon{
      &::before, &::after{
        border-color: $secondary !important;
      }
    }
  }
}
html body.wpb-js-composer .vc_tta-color-grey .vc_tta-panel .vc_tta-panel-heading,
html body.wpb-js-composer .vc_tta-color-grey .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after,
html body.wpb-js-composer .vc_tta-color-grey .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before{
  color: #aaaaaa  !important;
}

html body.wpb-js-composer .vc_tta-color-black .vc_tta-panel.vc_active .vc_tta-panel-heading,
html body.wpb-js-composer .vc_tta-color-black .vc_tta-panel .vc_tta-panel-heading {
  background-color: $tertiary !important;
}

html body.wpb-js-composer .vc_tta-color-grey .vc_tta-panel.vc_active .vc_tta-panel-heading,
html body.wpb-js-composer .vc_tta-color-grey .vc_tta-panel .vc_tta-panel-heading {
  background-color:  $quaternary !important;
}

html body.wpb-js-composer .vc_tta-color-grey .vc_tta-panel.vc_active .vc_tta-panel-heading{
  border-color: #222;
}

html body.wpb-js-composer .vc_tta-color-grey .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before{
  border-color: $secondary !important;
}
html body.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon{
 top: 59%;
}

//GELB
html body.wpb-js-composer .vc_tta-color-orange .vc_tta-panel .vc_tta-panel-heading,
html body.wpb-js-composer .vc_tta-color-orange .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after,
html body.wpb-js-composer .vc_tta-color-orange .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before{
  color: $secondary !important;
}

html body.wpb-js-composer .vc_tta-color-orange .vc_tta-panel.vc_active .vc_tta-panel-heading,
html body.wpb-js-composer .vc_tta-color-orange .vc_tta-panel .vc_tta-panel-heading {
  background-color: $primary !important;
}

html body.wpb-js-composer .vc_tta-color-orange .vc_tta-panel.vc_active .vc_tta-panel-heading{
  border-color: #222;
}

html body.wpb-js-composer .vc_tta-color-orange .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before{
  border-color: $tertiary !important;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-color-orange {
  *{
    color: $tertiary !important;
  }
  .vc_tta-panel-heading{
    border-radius: 0px !important;
    .vc_tta-title-text{
      font-size: 16*$px-base;
      font-weight: bold;
    }
    *{
      color: $tertiary !important;
      border-color: $tertiary !important;
    }
    .vc_tta-controls-icon{
      &::before, &::after{
        border-color: $tertiary !important;
      }
    }
  }
}

// TOUR ELEMENT GRAU
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-flat.vc_tta-tabs .vc_tta-panels{
  background-color: $quaternary !important;
  *{
    color: #ffffff !important;
  }
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-flat .vc_tta-tab>a {
  background-color: $quaternary !important;
  *{
    color: #ffffff !important;
  }
}

.wpb-js-composer .vc_tta-color-grey.vc_tta-style-flat .vc_tta-tab.vc_active>a{
  background-color: $tertiaryhover !important;
}

// TOUR ELEMENT DUNKEL GRAU
.wpb-js-composer .vc_tta-color-black.vc_tta-style-flat.vc_tta-tabs .vc_tta-panels{
  background-color: $tertiary !important;
  *{
    color: #ffffff !important;
  }
}
.wpb-js-composer .vc_tta-color-black.vc_tta-style-flat .vc_tta-tab>a {
  background-color: $tertiary !important;
  *{
    color: #ffffff !important;
  }
}

.wpb-js-composer .vc_tta-color-black.vc_tta-style-flat .vc_tta-tab.vc_active>a{
  background-color: $tertiaryhover !important;
}

// TOUR ELEMENT GELB
.wpb-js-composer .vc_tta-color-orange.vc_tta-style-flat.vc_tta-tabs .vc_tta-panels{
  background-color: $primary !important;
  *{
    color: #ffffff !important;
  }
}
.wpb-js-composer .vc_tta-color-orange.vc_tta-style-flat .vc_tta-tab>a {
  background-color: $primary !important;
  *{
    color: #ffffff !important;
  }
}

.wpb-js-composer .vc_tta-color-orange.vc_tta-style-flat .vc_tta-tab.vc_active>a{
  background-color: $primaryhover !important;
}

.vc_separator.vc_sep_color_orange .vc_sep_line {
  border-color: $primary !important;
}

html body .vc_slide.vc_images_carousel{
  width: 100% !important;
}

.vc_images_carousel .vc_carousel-control .icon-next,
.vc_images_carousel .vc_carousel-control .icon-prev{
  background-color: #ffffff;
  opacity: .95;
  border-radius: 20px;
}