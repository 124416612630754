/* -----------------------------------------
   NEXT FORMS
----------------------------------------- */

#nex-forms .row{
  margin: 0 !important;
}

input[type=checkbox]{
  width: 50px !important;
}

.wpcf7-form input[type="radio"], .wpcf7-form input[type="checkbox"]{
  width: inherit !important;
}

.wpcf7-form input.anzahlfeld{
  width: 45px !important;
}

.wpcf7-form input[type="text"], .wpcf7-form input[type="email"]{
  width: 100%;
  padding: 10px;
}

.wpcf7 .wpcf7-checkbox label{
  display: block;
  font-weight: normal;
}

.wpcf7 .anzahlfeld {
  width: 30px;
}

.wpcf7 label{
  width: 100%;
}

.nf-form-cont .nf-form-content {
  padding: 0px;
  max-width: 100%;

  .nf-breadcrumbs{
    a{
      text-decoration: none;
      border-radius: 3px;
    }
  }
  input[type=text]{
    color: $tertiary !important;
  }
  input[type=button]{
      display: inline-block;
      display: inline;
      text-decoration: none !important;
      border-radius: 3px;
      padding: 10px 15px;
      align-self: start;
      font-weight: bold;
      font-size: 0.875rem;
      background-color: $primary;
      color: $tertiary;
    &:hover{
      background-color: $primaryhover;
    }
  }
  .nf-mp-footer {
    input{
      width: inherit !important;
    }
  }
}