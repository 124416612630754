
/* -----------------------------------------
   TN STYLES
----------------------------------------- */

#bloginfobox ul {
  list-style: none;
}

.postoverviewpost {
  padding-bottom: 10px;
}

.pf-content img{
  max-width: 100%;
  height: auto;
}

.wp_rp_footer {
  display: none;
}

#recaptcha_area, #recaptcha_table {
  margin-left: -30px;
}

.fullpage .navicol {
  padding-top: 30px;
  margin-top: 30px;
  border-top: solid 1px #005a9b !important;
}


hr.blue {
  border: none;
  border-bottom: solid 1px #005a9b;
}

.innercontentfull {
  width: 100%;
}

.post-post-navigation, .paginate_div {
  background-image: url('css/images/white90.png');
  padding: 15px;
  margin-bottom: 10px;
}

.post-post-navigation div {
  text-align: center;
}

.post-post-navigation div a {
  font-weight: bold;
}

.bottomnav .paddingbox {
  padding-right: 20px;
}

.paginate_div a {
  font-size: 16px;
  font-weight: bold;
  border: solid 1px #666666 !important;
}

img.alignleft {
  margin-right: 15px;
  margin-bottom: 15px;
  float: left;
}

img.alignright {
  margin-left: 15px;
  margin-bottom: 15px;
  float: right;
}

.sil_rss_widget_list {
  padding-left: 0;
}

.sil_rss_widget_list li {
  padding-top: 7px;
  padding-bottom: 7px;
}

#wpfb-credits a{
  display: none !important;
}

#newsletteranmeldebalken{
  background-image: url(images/trans-bg.png);
  padding: 10px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

#newsletteranmeldebalken a{
  display: block;
}

body.page-template-page-team div.printfriendly{
  display: none !important;
}

.mitarbeiterinfo div {
  float: left;
  padding-left: 30px;
}

.mitarbeiterinfo div:first-child {
  padding-left: 0;
}

.mitarbeiterinfo div img {
  float: left;
}

.mitarbeiterinfo div span {
  padding-left: 5px;
  padding-top: 5px;
}

.grid-accordion {
  margin-left: 0;
}

.specialdownload {

  width: 500px;
  float: right;
  margin: 0;
}

.dynamic-facets .btn {
  white-space: normal;
  text-align: left;
}

.page-id-56823 table, .page-id-56823 thead, .page-id-56823 tbody, .page-id-56823 th, .page-id-56823 td, .page-id-56823 tr {
  display: block;
}

.page-id-56823 .table td {
  position: relative;
}

.page-id-56823 .table td:before {
  position: absolute;
  width: 100%;
  white-space: nowrap;
}

