.beteiligungsangebote{
  .headbox{
    background-color: $quaternary;
    padding: 20px;
  }
  a{
    color: $tertiary;
  }
  .footbox{
    background-color: $quaternary;
    padding: 20px;

    h3{
      font-size: 20px;
    }
  }
  .thumbnailboxwithquelle{
    width: 334px !important;
    padding-bottom: inherit !important;
    img{
      width: 334px !important;
    }
  }
  .ba-box{
    display: flex;
    div{
      display: inline-block;
    }

    .label{
      flex-shrink: 0  ;
      width: 30px;
      margin-right: 15px;
      text-align: center;
    }
  }
  .button{
    display: inline-block;
    display: inline;
    text-decoration: none !important;
    border-radius: 3px;
    padding: 10px 10px 10px 10px;
    align-self: start;
    font-weight: bold;
    font-size: 0.875rem;
    background-color: $primary;
    color: $tertiary;
  }
  .page{
    max-width: 1140px !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }


  .page{
    margin: auto;
    padding: 0px;
  }

  .postgridview{
    padding-top: 30px;

    .type-beteiligungsangebote{
      .gueltigbis{
        text-decoration: underline;
      }
      a:hover{
        text-decoration: none;
        .article{
          background-color: $quaternary;
          h3{
            color: $tertiary
          }
        }
      }
      .article{
        background-color: $quaternary_light;
        padding: 20px;
        min-height: 400px;
      }


      h3{
        font-size: 22px;
      }
      .read-more a{
        font-weight: bold;
      }
      dt {
        width: 30px !important;
      }
      dt.euro{
        width: 25px !important;
      }
      dd{
        margin-left: 40px !important;
        @include media-breakpoint-down(sm) {
          margin-left: 0px !important;
        }
      }
    }
  }
}