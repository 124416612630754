.single-best-practice-pool{
  .thumbnailboxwithquelle{
    width: 334px;
  }
}

.blogcontentmain{
  h2, h3, h4{
    font-weight: bold !important;
  }
}

.best-practice-pool{
  .best_practice_kategorie{
    display: flex;

    div{
      width: 35px;
      border-radius: 100px;
      background-color: $primary;
      margin: 5px;
    }
  }

  .best_practice_kategorie-legende{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 850px;
    margin: auto;
    padding-top: 25px;

    .katitem{
      padding: 0px 10px;
      &.headline{
        font-size: 12*$px-base;
      }
      div{
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 12*$px-base;
        img{
          width: 35px;
        }
      }
    }
  }

  .resultcol{
    display: flex;
    align-items: center;
    justify-content: center;
    .result-info{
      min-width: 200px;
      border-radius: 5px;
      display: inline-block;
      font-weight: bold;
      padding: 10px 35px !important;
    }
  }

  .thumbnailboxwithquelle{
    //width: inherit !important;
  }

  .downloadbox{
    background-color: $primary;
    padding: 25px 50px;
    margin: 35px 0px;

    .headlinebox{
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      img{
        width: 35px;
        display: inline;
      }

      h3{
        display: inline;
        font-size: 20*$px-base;
        margin-bottom: 0px;
      }
    }

  }

  .postoptions{
    .best_practice_kategorie_detail{
      display: flex;
      width: 100%;
      flex-direction: column;
      .katitem{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold !important;
        font-size: 11px;
        .icon{
          width: 40px;
          border-radius: 100px;
          background-color: $primary;
          margin: 5px;
        }
      }
    }
  }
}

.singlepost.best-practice-pool{
  .thumbnailboxwithquelle{
    height: inherit !important;
  }
}

.type-best-practice-pool .card .card-body{
  padding-top: 20px;
}