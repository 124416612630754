/* -----------------------------------------
   EVENT PLUGIN STYLE
----------------------------------------- */

.ecs-event .card .card-body p .text-muted {
  color: $primary !important;
}

@include media-breakpoint-up(md) {
  .ecs-events {
    .ecs-event:nth-child(2n) {
      border-left: solid 1px $tertiary;
      border-right: solid 1px $tertiary;
    }
  }
}
@include media-breakpoint-down(xs) {
  .ecs-events {
    .ecs-event:nth-child(2n) {
      border-top: solid 1px $tertiary;
      border-bottom: solid 1px $tertiary;
    }
  }
}

html body.single-tribe_events #tribe-events-pg-template, .tribe-events-pg-template{
  max-width: 100%;
  padding: 0px;
}

html body.single-tribe_events .tribe-events-pg-template>#tribe-events{
  padding: 0px;
}

.ecs-event {
  .ecs-wrap{
    background-color: transparent !important;

    .singleevent{
      text-align: center;
    }

    .text-muted{
      * {
        font-weight: bold;
        color: $tertiary !important;
        text-transform: uppercase;
      }
    }
    .card-title{
      a{
        color: $tertiary;
        font-size: 22*$px-base;
      }
    }
    .icon{
      width: 62px;
      height: 62px;
      margin:auto;
      path, rect{
        fill: $tertiary;
      }
    }
    .card-footer{
      .btn{
        background-color: #d5dde0;
        color: $primary;
        border-color: $primary;
      }
    }
    .card-body{
      min-height: 160px;
    }
    .card-img-top{
      height: auto;
    }
    .tnbutton{
      margin-top: 35px;
      justify-content: center;
      a{
        text-decoration: none !important;
        background-color: $primary;
        color: $tertiary;
        &:hover{
          background-color: $primaryhover;
        }
      }
    }
  }
}


html body #tribe-events, html body .tribe-events, html body #tribe-events-content{
  *{
    font-size: 16px;
    font-family: $font-family-base !important;
  }
  a{
    color: $tertiary;
    text-decoration: underline;
    border-bottom: none;
  }
  .tribe-events-c-search__button, .tribe-events-c-subscribe-dropdown__button{
    background-color: $primary !important;
    color: $tertiary !important;
    border: transparent !important;
  }
  .tribe-events-ical, .tribe-events-gcal{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.175rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 20px;
    text-transform: uppercase;
    background-color: #ffffff;
    border-color: #000000;
    color: #000000;
    font-size: 12*$px-base;
  }

  .tribe-events-event-image {
    text-align: left;
    @include media-breakpoint-down(md) {
      display: block;
      float: none;
    }
    img {
      max-width: 365px;
      height: auto;
    }
  }
  #second-container{
    overflow: visible;
    .container, .row{
      margin: 0;
      padding: 0;
    }
  }
  .tribe-events-event-meta{
    background-color: $quaternary;
    width: 100%;
    padding: 0px 25px 25px 25px;

    a{
      color: #ffffff;
      text-decoration: underline;
    }
  }
  .tribe-events-event-meta:before{
    border: none;
  }
  .tribe-events-title-bar{
    display: none;
  }
}

#tribe-events-header{
  .tribe-events-sub-nav{
    display: none;
  }
}

.tribe-events-tooltip{
  .tribe-events-event-thumb{
    display: none;
  }
}

.tribeteilnehmerliste{
  width: 100%;
}

.tribe-tickets-order_status-row, .tribe-tickets-attendees-list-optout{
  display: none !important;
}


html body #tribe-events .tribe-events-list{
  .tribe-events-event-meta{
    background-color: #ffffff !important;
  }
}

.tribe-events-related-events-title{
  font-size: 24*$px-base;
  color: $tertiary;
  text-align: center;
  margin: 40px 0px !important;
  background-image: url("images/icons/tnicons/tn3.svg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 90px;
  padding-top: 90px !important;
}

.tribe-related-events-thumbnail img{
  //width: 324px !important;
  //height: 172px !important;
}

//.tribe-related-events{
//  .tribe-related-event-info{
//    min-height: 270px;
//  }
//}

.tribe-related-events{
  .tribe-related-event-info{
    margin:20px;
  }
}

.tribe-events-tickets header{
  font-weight: bold !important;
}

.tribe-events-sub-nav a{
  color: #ffffff !important;
}

#tribe-events-footer{
  .tribe-events-sub-nav a{
    color: $tertiary !important;
  }
}

.tribe-mobile-day .tribe-events-event-image{
  display: none;
}

.tribe-events-list .tribe-events-list-event-title{
  background-color: #ffffff !important;
  color: #000000 !important;
}

.tribe-events-single ul.tribe-related-events li .tribe-related-event-info{
  width: inherit !important;
}

body .tribe-events .tribe-events-c-subscribe-dropdown__button{
  display: flex;
  align-items: center;
}

.event-tickets .tribe-tickets__rsvp-actions .tribe-common-c-btn,
.tribe-common .tribe-common-c-btn, .tribe-common a.tribe-common-c-btn, .tribe-common button.tribe-common-c-btn{
  background-color: #fffc00 !important;
  color: #000000 !important;
}

html body .tribe-tickets__rsvp-ar-quantity{
  display: none !important;
}

span.tribe-common-h2.tribe-common-h6--min-medium {
  visibility: hidden;
  position: relative;
}
span.tribe-common-h2.tribe-common-h6--min-medium::after {
  content: "Hier buchen";
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
}